<template>
    <IonPage>
        <Header :title="getJurorName" :back="true"></Header>
        <IonContent fullscreen>
            <IonGrid>
                <IonRow class="ion-justify-content-center ion-align-items-center">
                    <IonCol class="ion-margin-top ion-padding-top ion-text-center" size="12">
                        <ion-card color="light">
                            <ion-card-header>
                                <ion-card-title>Abmelden</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                                <p>Möchten Sie sich wirklich abmelden?</p>
                                <p> Ihre nicht abgeschlossenen Bewertungen bleiben
                                    gespeichert!</p>
                                <ion-button class="ion-margin" expand="block" @click="logout">Abmelden</ion-button>
                            </ion-card-content>
                        </ion-card>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow
} from '@ionic/vue';
import Header from "../components/Header.vue";
import {removeUserFromStorage, removeUserRatingFromStorage} from "@/util/LocalStateService";
import {loggedInJuror} from "@/util/JuryService";

export default {
    name: "Logout",
    components: {
        Header,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        IonContent,
        IonCardContent,
        IonCard,
        IonCardHeader,
        IonCardTitle,
        IonButton
    },
    computed: {
        getJurorName(){
            if ( loggedInJuror.value) {
                return 'Juror: ' + loggedInJuror.value._firstName +' '+ loggedInJuror.value._lastName
            }
            return ''
        }
    },
    methods: {
        async logout() {
            await removeUserFromStorage()
            removeUserRatingFromStorage().then(()=>{
                window.location.href = window.location.origin;
            })
        }
    }
}
</script>

<style scoped>

</style>
